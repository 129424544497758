<template>
  <div>
    <div>
      <input
        ref="upload"
        style="display: none"
        type="file"
        @change="onChange"
        :multiple="multiple"
      />
      <a-button :loading="loading" icon="upload" @click="onClick">
        {{ list.length > 0 ? "重新上传" : title }}
      </a-button>
    </div>

    <template v-if="fileObjectList.length > 0">
      <div class="file" v-for="(item, index) in fileObjectList" :key="item.id">
        <a
          target="_blank"
          download
          :href="item.completePath"
          title="点击下载"
          >{{ item.name }}</a
        >
        <a
          href="#"
          class="danger"
          style="padding-left: 8px"
          @click="remove(index)"
          >删除</a
        >
      </div>
    </template>
    <template v-if="pathList.length > 0">
      <div class="file" v-for="(item, index) in pathList" :key="item">
        <template v-if="type === 'file'">
          <a
            v-if="type === 'file'"
            target="_blank"
            download
            :href="item"
            title="点击下载"
            >{{ item }}</a
          >
          <a
            href="#"
            class="danger"
            style="padding-left: 8px"
            @click="remove(index)"
            >删除</a
          >
        </template>

        <img v-if="type === 'image'" :src="item" alt="" />
      </div>
    </template>
  </div>
</template>

<script>
import { uploadFile } from "@/api/upload";
export default {
  props: {
    type: {
      type: String,
      default: "file", // image
    },
    // 是否可以多选
    multiple: {
      type: Boolean,
      default: false,
    },
    list: {
      type: Array,
      default: () => [],
    },
    title: {
      type: String,
      default: "上传附件",
    },
  },

  data() {
    return {
      loading: false,
    };
  },

  computed: {
    fileObjectList() {
      return this.list.filter((item) => item.id);
    },
    pathList() {
      if (this.fileObjectList.length === 0) {
        return this.list;
      } else {
        return [];
      }
    },
  },

  methods: {
    onClick() {
      this.$refs.upload.click();
    },

    onChange(e) {
      const files = e.target.files;
      const data = new FormData();
      for (let i = 0; i < files.length; i++) {
        data.append("files", files[i]);
      }

      this.loading = true;

      uploadFile(data)
        .then((res) => {
          console.log("upload res", res);
          if (Array.isArray(res)) {
            if (this.multiple) {
              this.$emit("change", [...this.list, ...res]);
            } else {
              this.$emit("change", [...res]);
            }
          }
        })
        .finally(() => {
          this.loading = false;
          e.target.value = null;
        });
    },

    remove(index) {
      const arr = [...this.list];
      arr.splice(index, 1);
      this.$emit("change", arr);
    },
  },
};
</script>

<style lang="less" scoped>
.file {
  display: flex;
  align-items: center;
  background-color: #f9f9f9;
  margin-bottom: 8px;
}
.file img {
  width: 100%;
}
</style>