var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('Pane'),_c('div',{staticClass:"container"},[_c('a-form',{attrs:{"form":_vm.form,"label-col":{ span: 8 },"wrapper-col":{ span: 12 },"colon":false},on:{"submit":_vm.handleSubmit}},[_c('a-row',[_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"档案编号"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'code',
                { rules: [{ required: true, message: '请输入！' }] },
              ]),expression:"[\n                'code',\n                { rules: [{ required: true, message: '请输入！' }] },\n              ]"}]})],1)],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"档案名称"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'name',
                { rules: [{ required: true, validator: _vm.validator }] },
              ]),expression:"[\n                'name',\n                { rules: [{ required: true, validator }] },\n              ]"}]})],1)],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"档案类型"}},[_c('a-cascader',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'type',
                { rules: [{ required: true, message: '请选择！' }] },
              ]),expression:"[\n                'type',\n                { rules: [{ required: true, message: '请选择！' }] },\n              ]"}],attrs:{"placeholder":"","options":_vm.typeList,"fieldNames":{
                label: 'name',
                value: 'value',
                children: 'children',
              }}})],1)],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"保密级别"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'level',
                { rules: [{ required: true, message: '请选择！' }] },
              ]),expression:"[\n                'level',\n                { rules: [{ required: true, message: '请选择！' }] },\n              ]"}]},_vm._l((_vm.levelList),function(item){return _c('a-select-option',{key:item.value,attrs:{"value":item.value}},[_vm._v(" "+_vm._s(item.name)+" ")])}),1)],1)],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"页数"}},[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'pageNumber',
                { rules: [{ required: true, message: '请输入！' }] },
              ]),expression:"[\n                'pageNumber',\n                { rules: [{ required: true, message: '请输入！' }] },\n              ]"}],staticStyle:{"width":"100%"},attrs:{"min":0}})],1)],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"档案位置"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['location']),expression:"['location']"}]})],1)],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',[_c('span',{staticClass:"required",attrs:{"slot":"label"},slot:"label"},[_vm._v("移交人")]),_c('div',[_c('EmployeeSelector',{attrs:{"value":_vm.user.name ? [_vm.user] : []},on:{"change":(arr) => {
                    if (arr.length > 0) {
                      _vm.user = arr[0];
                    } else {
                      _vm.user = {};
                    }
                  }}},[_c('a-button',{staticStyle:{"text-align":"left"},attrs:{"block":""}},[(_vm.user.name)?_c('div',[_vm._v(" "+_vm._s(_vm.user.name)+" ")]):_c('div',[_vm._v(" ")])])],1)],1)])],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"移交部门"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'transfereeDeptId',
                { rules: [{ required: true, message: '请选择！' }] },
              ]),expression:"[\n                'transfereeDeptId',\n                { rules: [{ required: true, message: '请选择！' }] },\n              ]"}],staticStyle:{"width":"100%"},attrs:{"placeholder":"","show-search":"","filter-option":_vm.$selectFilterOption}},_vm._l((_vm.organizationList),function(item){return _c('a-select-option',{key:item.id,attrs:{"value":item.id}},[_vm._v(_vm._s(item.uniqueName))])}),1)],1)],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"移交日期"}},[_c('a-date-picker',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'createAt',
                { rules: [{ required: true, message: '请选择！' }] },
              ]),expression:"[\n                'createAt',\n                { rules: [{ required: true, message: '请选择！' }] },\n              ]"}],staticStyle:{"width":"100%"},attrs:{"placeholder":""}})],1)],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"保管期限(月)"}},[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:(['duration']),expression:"['duration']"}],staticStyle:{"width":"100%"},attrs:{"min":0,"placeholder":"若不填写则代表长期保存"}})],1)],1),_c('a-col',{attrs:{"span":24}},[_c('a-form-item',{attrs:{"label":"关键词","label-col":{ span: 4 },"wrapper-col":{ span: 18 }}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['keyWords']),expression:"['keyWords']"}],attrs:{"placeholder":"输入关键词，多个关键词之间用逗号隔开"}})],1)],1),_c('a-col',{attrs:{"span":24}},[_c('a-form-item',{attrs:{"label":"档案说明","label-col":{ span: 4 },"wrapper-col":{ span: 18 }}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:(['remarks']),expression:"['remarks']"}],attrs:{"auto-size":{ minRows: 3 }}})],1)],1),_c('a-col',{attrs:{"span":24}},[_c('a-form-item',{attrs:{"label-col":{ span: 4 },"wrapper-col":{ span: 18 }}},[_c('span',{attrs:{"slot":"label"},slot:"label"},[_vm._v("附件")]),_c('Uploader',{attrs:{"list":_vm.fileList},on:{"change":(arr) => (_vm.fileList = arr)}})],1)],1)],1),_c('div',{staticClass:"center"},[_c('a-space',[_c('a-button',{attrs:{"type":"primary","html-type":"submit","loading":_vm.loading}},[_vm._v("保存")]),_c('a-button',{on:{"click":function($event){return _vm.$close(_vm.$route.path)}}},[_vm._v("关闭")])],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }