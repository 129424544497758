import request from '../request'

export function fetchList(params) {
    return request({
        url: '/office-service/arcArchive/search',
        params
    })
}

// 新增或移交档案，后端判断是否需要发审批
export function add(data) {
    return request({
        url: '/office-service/arcArchive/add',
        method: 'post',
        data,
    })
}

// 重新移交
export function transfer(data) {
    return request({
        url:
            '/office-service/arcArchive/reAdd',
        method: "post",
        data
    })
}

export function edit(data) {
    return request({
        url: '/office-service/arcArchive/update',
        method: 'post',
        data,
    })
}

export function remove(data) {
    return request({
        url: '/office-service/arcArchive/del',
        method: 'post',
        data,
    })
}

// 出借档案
export function borrow(data) {
    return request({
        url: '/office-service/arcBorrow/add',
        method: 'post',
        data,
    })
}

// 获取出借记录
export function fetchBorrowList(params) {
    return request({
        url: '/office-service/arcBorrow/list',
        params,
    })
}

// 获取出借记录详情，详情中包括需要借的档案列表
export function fetchBorrowDetail(params) {
    return request({
        url: `/office-service/arcBorrow/query/${params.id}`,
    })
}

// 确认出借
export function confirmBorrow(data) {
    return request({
        url: '/office-service/arcBorrow/sure',
        data,
        method: "post"
    })
}
// 作废出借
export function cancelBorrow(params) {
    return request({
        url: `office-service/arcBorrow/invalid/${params.id}`,
        method: "POST"
    })
}

// 重新出借
export function reBorrow(data) {
    return request({
        url: '/office-service/arcBorrow/reAdd',
        method: 'post',
        data
    })
}

// 获取出借记录中的档案列表
export function fetchBorrowArchiveList(params) {
    return request({
        url: '/office-service/arcBorrow/archiveList',
        params,
    })
}

// 档案归还
export function backArchive(data) {
    return request({
        url: '/office-service/arcBorrow/back',
        method: "POST",
        data,
    })
}

export function receive(data) {
    return request({
        url: '/office-service/arcArchive/receive',
        method: "POST",
        data,
    })
}