<template>
  <div>
    <Pane />

    <div class="container">
      <a-form
        :form="form"
        :label-col="{ span: 8 }"
        :wrapper-col="{ span: 12 }"
        :colon="false"
        @submit="handleSubmit"
      >
        <a-row>
          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="档案编号">
              <a-input
                v-decorator="[
                  'code',
                  { rules: [{ required: true, message: '请输入！' }] },
                ]"
              />
            </a-form-item>
          </a-col>

          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="档案名称">
              <a-input
                v-decorator="[
                  'name',
                  { rules: [{ required: true, validator }] },
                ]"
              />
            </a-form-item>
          </a-col>

          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="档案类型">
              <a-cascader
                placeholder=""
                v-decorator="[
                  'type',
                  { rules: [{ required: true, message: '请选择！' }] },
                ]"
                :options="typeList"
                :fieldNames="{
                  label: 'name',
                  value: 'value',
                  children: 'children',
                }"
              />
            </a-form-item>
          </a-col>

          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="保密级别">
              <a-select
                v-decorator="[
                  'level',
                  { rules: [{ required: true, message: '请选择！' }] },
                ]"
              >
                <a-select-option
                  v-for="item in levelList"
                  :key="item.value"
                  :value="item.value"
                >
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>

          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="页数">
              <a-input-number
                :min="0"
                v-decorator="[
                  'pageNumber',
                  { rules: [{ required: true, message: '请输入！' }] },
                ]"
                style="width: 100%"
              />
            </a-form-item>
          </a-col>

          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="档案位置">
              <a-input v-decorator="['location']" />
            </a-form-item>
          </a-col>

          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item>
              <span class="required" slot="label">移交人</span>

              <div>
                <EmployeeSelector
                  @change="
                    (arr) => {
                      if (arr.length > 0) {
                        user = arr[0];
                      } else {
                        user = {};
                      }
                    }
                  "
                  :value="user.name ? [user] : []"
                >
                  <a-button block style="text-align: left">
                    <div v-if="user.name">
                      {{ user.name }}
                    </div>
                    <div v-else>&nbsp;</div>
                  </a-button>
                </EmployeeSelector>
              </div>
            </a-form-item>
          </a-col>
          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="移交部门">
              <a-select
                placeholder=""
                show-search
                :filter-option="$selectFilterOption"
                style="width: 100%"
                v-decorator="[
                  'transfereeDeptId',
                  { rules: [{ required: true, message: '请选择！' }] },
                ]"
              >
                <a-select-option
                  v-for="item in organizationList"
                  :key="item.id"
                  :value="item.id"
                  >{{ item.uniqueName }}</a-select-option
                >
              </a-select>
            </a-form-item>
          </a-col>

          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="移交日期">
              <a-date-picker
                placeholder=""
                v-decorator="[
                  'createAt',
                  { rules: [{ required: true, message: '请选择！' }] },
                ]"
                style="width: 100%"
              />
            </a-form-item>
          </a-col>

          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="保管期限(月)">
              <a-input-number
                :min="0"
                placeholder="若不填写则代表长期保存"
                v-decorator="['duration']"
                style="width: 100%"
              />
            </a-form-item>
          </a-col>

          <a-col :span="24">
            <a-form-item
              label="关键词"
              :label-col="{ span: 4 }"
              :wrapper-col="{ span: 18 }"
            >
              <a-input
                placeholder="输入关键词，多个关键词之间用逗号隔开"
                v-decorator="['keyWords']"
              />
            </a-form-item>
          </a-col>

          <a-col :span="24">
            <a-form-item
              label="档案说明"
              :label-col="{ span: 4 }"
              :wrapper-col="{ span: 18 }"
            >
              <a-textarea
                :auto-size="{ minRows: 3 }"
                v-decorator="['remarks']"
              />
            </a-form-item>
          </a-col>

          <a-col :span="24">
            <a-form-item :label-col="{ span: 4 }" :wrapper-col="{ span: 18 }">
              <span slot="label" class="">附件</span>
              <Uploader :list="fileList" @change="(arr) => (fileList = arr)">
              </Uploader>
            </a-form-item>
          </a-col>
        </a-row>

        <div class="center">
          <a-space>
            <a-button type="primary" html-type="submit" :loading="loading"
              >保存</a-button
            >
            <a-button @click="$close($route.path)">关闭</a-button>
          </a-space>
        </div>
      </a-form>
    </div>
  </div>
</template>

<script>
import organization from "@/mixins/organization";
import EmployeeSelector from "@/components/employee-selector";
import Uploader from "@/components/uploader";

import { mapGetters } from "vuex";
import { add } from "@/api/archives";

export default {
  name: "addArchive",

  mixins: [organization],
  components: {
    EmployeeSelector,
    Uploader,
  },

  data() {
    return {
      form: this.$form.createForm(this),
      user: {},

      fileList: [],

      loading: false,
    };
  },

  computed: {
    ...mapGetters("setting", ["findDataDict"]),
    levelList() {
      return this.findDataDict("secretLevel");
    },
    typeList() {
      return this.findDataDict("arcType");
    },
  },

  methods: {
    validator(rule, value) {
      return new Promise((resolve, reject) => {
        if (!value) {
          reject("请输入！");
        } else {
          resolve();
        }
      });
    },

    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          if (!this.user.name) {
            this.$message.error("请选择移交人！");
            return;
          }

          const createAt = values.createAt.format("YYYY-MM-DD");

          const obj = this.organizationList.find(
            (item) => item.id === values.transfereeDeptId
          );

          this.loading = true;

          add([
            {
              ...values,
              createAt,

              // 移交人
              transferee: this.user.userId,
              transfereeName: this.user.name,

              // 移交部门
              transfereeDeptId: values.transfereeDeptId,
              transfereeDeptName: obj ? obj.uniqueName : "",

              type: values.type.join(","),

              attachments: this.fileList.map((item) => item.id).join(","),
            },
          ])
            .then(() => {
              this.form.resetFields();
            })
            .finally(() => {
              this.loading = false;
            });
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.container {
  background-color: #fff;
  padding: 24px 160px;
}

.center {
  margin-top: 80px;
}
</style>
